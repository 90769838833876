:root {
  --primary-400: #080808;
  --primary-300: #0d0d0d;
  --primary-200: #737373;
  --primary-100: #e7e7e7;
  --primary-50: #f7f7f7;

  --secondary-300: #017373;
  --secondary-200: #048c80;
  --secondary-100: #aad9d1;

  --accent: #efece6;

  --gradient-bg: rgb(1, 115, 115);
  --gradient: linear-gradient(
    25deg,
    rgba(1, 115, 115, 1) 0%,
    rgba(170, 217, 209, 1) 100%
  );
}

.body {
  margin: 0px;
  padding: 0px;
  background-color: var(--primary-400);
}

.text-large {
  font-family: "Be Vietnam Pro";
  font-size: 20px;
  line-height: 150%;
  margin: 0px;
}

.text-reg {
  font-family: "DM Sans";
  font-size: 16px;
  line-height: 150%;
  margin: 0px;
}

.text-med {
  font-family: "DM Sans";
  font-size: 18px;
  line-height: 150%;
  margin: 0px;
}

.text-small {
  font-family: "DM Sans";
  font-size: 14px;
  line-height: 140%;
  margin: 0px;
}

.text-tiny {
  font-family: "DM Sans";
  font-size: 12px;
  line-height: 150%;
  margin: 0px;
}

.h1 {
  font-family: "DM Sans";
  font-size: 56px;
  line-height: 106%;
  letter-spacing: -3px;
  margin: 0px;
}

.h2 {
  font-family: "DM Sans";
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -3px;
  margin: 0px;
}

.h3 {
  font-family: "Be Vietnam Pro";
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.64px;
  margin: 0px;
}

.h5 {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.64px;
  margin: 0px;
}
