.logos {
  overflow: hidden;
  padding: 33px 0px;
  position: relative;
}

.logos__slide {
  white-space: nowrap;
  max-width: calc(100% - (87px * 2));
  margin: auto;
  animation: 40s rotate infinite linear;
}

.logo {
  margin: 0px 40px;
}

.logos__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(100% / 2);
}

.logos__overlay_left {
  left: 0;
  background: linear-gradient(to left, rgba(8, 8, 8, 0), rgb(8, 8, 8));
}
.logos__overlay_right {
  right: 0;
  background: linear-gradient(to right, rgba(8, 8, 8, 0), rgb(8, 8, 8));
}

@keyframes rotate {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
